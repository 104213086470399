import axios from "axios";

import { loginRequesting, loginSuccess, loginError } from "./../actionType";
import Utils from "../../utils/config";

export function onLoginRequesting() {
  return {
    type: loginRequesting,
  };
}

export function onLoginSuccess(puTaoToken) {
  return {
    type: loginSuccess,
    value: puTaoToken,
  };
}

export function onLoginError(data) {
  return {
    type: loginError,
    value: data,
  };
}

export function onLogin(data) {
  return (dispatch) => {
    dispatch(onLoginRequesting());
    return axios({
      method: "POST",
      url: Utils.writingUrl() + "/programmer/jwt/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        let data = Utils.requestStatus(res, "登录");
        if (res && res.status === 200) {
          let spikeToken = data.data.jwt_token;
          let userInfo = JSON.stringify(data.data.user_info);
          let userData = JSON.stringify(data.data)
          localStorage.setItem("spikeToken", spikeToken);
          localStorage.setItem("userInfo", userInfo);
          localStorage.setItem('userData', userData)
          dispatch(onLoginSuccess(spikeToken));
        }
      })
      .catch((res) => {
        Utils.requestStatus(res.response, "登录");
        dispatch(onLoginError()); //// 登录失败关闭登录lodding动画
      });
  };
}
