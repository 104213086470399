import React from "react";
import { Button, Form, Input } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as login from "../../store/actions/login";
import Style from "./login.module.css";
import Utils from "../../utils/config";
import { withRouter } from "../../utils/withRouter";
import { Link } from "react-router-dom";
import Logo from './../../Static/images/Spike20.writing.logo.png'
class LoginPage extends React.Component {

    componentDidMount() {
        this.onLinkTo()
    }

    onLinkTo = () => {
        const spikeToken = Utils.getToken();
        if (spikeToken) {
            let path = this.props.location.pathname
            if (path === '/login') {
                path = '/'
            }
            this.props.navigate(path);
            window.location.reload(true);
        }
    }

    render() {
        const { acLogin, loginLodding } = this.props;

        // 登录表单提交
        const onFinish = (values) => {

            acLogin.onLogin(values).then(() => {
                this.onLinkTo()
            });
        };

        return (
            <div className={Style.login}>
                <div className={Style.loginBody}>
                    <div className={Style.loginLog}><img src={Logo} alt="Logo" /></div>
                    <div className={Style.loginForm}>
                        <Form
                            name="normal_login"
                            className={Style.loginForms}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            size='large'

                        >
                            <Form.Item
                                name="username"
                                className={Style.loginInput}
                                rules={[
                                    { required: true, message: "Please input your Username!" },
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Username"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: "Please input your Password!" },
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <div className={Style.loginButton}>
                                <Button className={Style.loginSumit} type="primary" htmlType="submit" loading={loginLodding}>
                                    Sign In
                                </Button>
                                <Button className={Style.gotoRegister} type="link"><Link to='/register'>Go to register</Link> </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
const stateToProps = (state) => {
    return {
        loginLodding: state.login.loginLodding,
    };
};
const dispatchToProps = (dispatch) => {
    return {
        dispatch,
        acLogin: bindActionCreators(login, dispatch),
    };
};
export default connect(stateToProps, dispatchToProps)(withRouter(LoginPage));
