import React from "react";
import Utils from "./config";
import LoginPage from "../Page/login/login";

export default function IsLogin(Component) {

    if (Utils.getToken()) {
        return <Component />
    } else {
        return <LoginPage />
    }
}

// export default function IsLogin(Component) {
//     class IsLogin extends React.Component {
//         componentDidMount() {
//             this.isLogin();
//         }
//         isLogin() {
//             if (!Utils.getToken()) {
//                 // 没有登陆
//                 const redirectAfterLogin = this.props.location.pathname;
//                 this.props.history.push(`/login?next=${redirectAfterLogin}`);
//             }
//         }
//         render() {
//             return <Component {...this.props} />;
//         }
//     }
//     return IsLogin;
// }
