// import axios from "axios";
import {message} from "antd";
// import store from './../store/index'
// import actionType from "../store/actionType";
class Util {
    getToken() {
        return localStorage.getItem("spikeToken");
    }

    getIp() {
        let http = window.location.protocol;
        let host = window.location.host;
        let ip = `${http}//${host}`;
        ip = ip.replace(":3000", ":8000");
        ip = ip.replace(":3001", ":8001");
        return ip;
    }

    writingUrl() {
        return this.getIp();

        // const isPrd = process.env.NODE_ENV === 'production';
        //区分开发环境还是生产环境基础URL
        // const basciUrl = isPrd ? 'https://exam.spiketwozero.com' : 'http://localhost:8000'
        // return basciUrl
    }

    requestUrl(path) {
        let paths = this.writingUrl() + path
        // console.log(paths)
        return paths
    }

    // get(data) {
    //     data["method"] = "get";
    //     console.log(data)
    //     this.axiosRequest(data)
    // }
    // post(data) {
    //     data["method"] = "post";
    //     this.axiosRequest(data)
    // }
    // detele(data) {
    //     data["method"] = "detele";
    //     this.axiosRequest(data)
    // }
    // put(data) {
    //     data["method"] = "put";
    //     this.axiosRequest(data)
    // }
    // patch(data) {
    //     data["method"] = "patch";
    //     this.axiosRequest(data)
    // }

    // axiosRequest(data) {

    //     store.dispatch({ type: actionType[data['actionType']][0] })
    //     let headers = {
    //         Accept: "application/json",
    //         Authorization: this.getToken(),
    //     }
    //     data['headers'] = headers
    //     let url = this.requestUrl(data['url'])
    //     data['url'] = url

    //     axios(data).then((res) => {
    //         store.dispatch({ type: actionType[data['actionType']][1], value: res })
    //     }).catch(() => {
    //         store.dispatch({ type: actionType[data['actionType']][2], value: null })

    //     })
    // }
    requestStatus(data, text) {
        if (!text) text = "";
        // message.destroy()
        if (data) {
            if (data.status) {
                if (data.status === 200 || data.status === 201 || data.status === 204) {
                    // message.success(`${text}成功`)
                } else if (data.status === 401) {
                    let textCode = JSON.stringify(data.data);
                    message.error(`${text}失败,${textCode}`);
                } else if (data.status === 403) {
                    let text = data.data[Object.keys(data.data)];
                    message.error(text);
                    if (text === "权限不足") {
                        window.location.href = `${window.location.protocol}//${window.location.host}/`;
                    }
                    if (text === "Authentication credentials were not provided.") {
                        localStorage.clear();
                        document.cookie = "jwt_token='';Path=/";

                        window.location.reload(true);
                    }
                } else if (data.status === 404) {
                    message.error(`${text}状态码：${data.data[Object.keys(data.data)]}`);
                } else if (data.status === 400) {
                    message.error(`${text}状态码：${data.data[Object.keys(data.data)][0]}`);
                } else {
                    message.error(`${text}状态码：${data.status}----发生未知错误`);
                }
            } else {
                message.error("发送请求失败！");
            }
        } else {
            message.error(`${text}状态码：${data}----发生未知错误`);
        }
        return data;
    }
}

let Utils = new Util()
export default Utils