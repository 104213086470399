import React, { lazy, Component, Suspense } from "react"
import { Routes, Route, Link, } from "react-router-dom";
import { Avatar, Button, Layout, Menu, } from 'antd';
import Logo from './Static/images/Spike20.writing.logo.png'
import { UserOutlined } from '@ant-design/icons';
import style from './AppRouter.module.css'
import IsLogin from "./utils/isLogin";
import Loading from "./components/loading/loading";
import { withRouter } from "./utils/withRouter";
import Utils from "./utils/config";
const IndexPage = lazy(() => import('./Page/index'))
const WritingPage = lazy(() => import('./Page/writing'))
const NotPage = lazy(() => import('./Page/404/404'))
const LoginPage = lazy(() => import('./Page/login/login'))
const RegisterPage = lazy(() => import('./Page/register'))
const { Header, Content } = Layout;

class AppRouter extends Component {
    state = {
        menuKye: ['/']
    }
    componentDidMount() {
        let menuKey = this.props.location.pathname
        this.setState({
            menuKye: [menuKey]
        })
    }
    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                menuKye: [nextProps.location.pathname]
            })
        }
    }
    OnMenuLink = (e) => {
        if (e.key === 'logout') {
            localStorage.clear();
            window.location.reload(true);
        } else {
            this.setState({
                menuKye: [e.key]
            })
        }

    }

    render() {
        const MenuItems = [
            {
                label: (<Link to={'/'}>Home</Link>),
                key: '/',
            },
            {
                label: (<Link to='writing'>Writing</Link>),
                key: '/writing',
            },

        ]
        const userInfo = localStorage.getItem("userInfo");
        const MenuUser = [{
            label: (<Avatar src={userInfo && userInfo.cover} icon={<UserOutlined />} alt={userInfo && userInfo.username} />),
            key: 'user',
            children: [
                { label: (<Button type="link">退出登录</Button>), key: 'logout' }
            ]
        }]
        const { menuKye } = this.state
        const isLogin = Utils.getToken()
        return (
            <div>

                <Layout theme='light'>
                    <Header className={style.antdHeader} >
                        <div className={style.header}>
                            <div className={style.headers}>
                                <div className={style.logo}>
                                    <Link to='/'>
                                        <img className={style.navLogo} src={Logo} alt="Log0" />
                                    </Link>
                                </div>
                                <div className={style.linkMenu}>
                                    <Menu selectedKeys={menuKye} theme='light' mode="horizontal" onClick={this.OnMenuLink.bind(this)} items={MenuItems} />
                                </div>
                            </div>
                            <div className={style.userMenu}>
                                {isLogin ? <Menu selectedKeys={menuKye} theme='light' mode='horizontal' triggerSubMenuAction='hover' onClick={this.OnMenuLink.bind(this)} items={MenuUser} /> : <Link to='/login'>登录</Link>}
                            </div>
                        </div>


                    </Header>

                    <Content className={style.antdContent}>
                        <Suspense fallback={<Loading />}>
                            <Routes>
                                <Route path="/" exact element={<IndexPage />} />
                                <Route path="/writing" element={IsLogin(WritingPage)} />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/register" element={<RegisterPage />} />
                                <Route path='*' element={<NotPage />} />
                            </Routes>
                        </Suspense>

                    </Content>
                </Layout>


            </div >

        )
    }

}

export default withRouter(AppRouter) 